import React, { useState } from 'react';
import { IonSpinner } from '@ionic/react';
import Collapsible from 'react-collapsible';
import CollapsibleTrigger from '../UI/CollapsibleTrigger';
import Row from './Row';
import ListBeverage from '../list/Beverage';

function MorganaRow(props) {
    const { course, index } = props;
    const [minorCourses, setMinorCourses] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);

    async function onOpening() {
        // populate courses with wrappedCourses
        setMinorCourses(course.map(c => c));
        setLoading(false);
    }

    function getMorganaCourseName() {
        if (Number(index) === 0) {
            return ({ name: "cucina" });
        } if (Number(index) === 1) {
            return ({ name: "fine sushi" });
        } if (Number(index) === 2) {
            return ({ name: "dolci" });
        } if (Number(index) === 3) {
            return ({ name: "vini/bevande" });
        }
    }

    return (
        <Collapsible
            className="morgana-row"
            tabIndex={index}
            trigger={<CollapsibleTrigger index={index} trigger={getMorganaCourseName()} setOpen={setOpen} wrapCourse></CollapsibleTrigger>}
            triggerWhenOpen={<CollapsibleTrigger index={index} trigger={getMorganaCourseName()} opened setOpen={setOpen} wrapCourse></CollapsibleTrigger>}
            onTriggerOpening={onOpening}
            open={open}>
            <div>
                {isLoading ?
                    <IonSpinner name="dots" /> :
                    <>
                        {Number(index) !== 3 ?
                            minorCourses.map((mc, i) =>
                                <Row course={mc} index={i} key={i} />
                            )
                            :
                            minorCourses.map((mc, i) => <ListBeverage beverage={mc} index={i} key={i} />)}
                    </>

                }
            </div>
        </Collapsible>
    );
}

export default MorganaRow;
