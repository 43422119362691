import React from 'react';
import { IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import "./UpdateAccountModalContentStyle.css";


function UpdateAccountModalContent({ closerHandler }) {

    function refreshMenu() {
        let params = new URLSearchParams(window.location.search);
        document.location.href = document.location.origin + (params.get("type") && params.get("token")) ? `/${params.get("type")}/q/${params.get("token")}/menu` : "/";
    }

    return (
        <div className="update-account-modal-content overflow-y-scroll text-black flex flex-col items-center text-center">
            <button type="button" onClick={closerHandler} className="z-10 absolute top-0 right-0 flex items-center justify-center mt-2 mr-4 p-2 rounded-full shadow-lg bg-white">
                <IonIcon slot="icon-only" icon={close}></IonIcon>
            </button>

            <p className="w-11/12">Attenzione! Se hai modificato la tua <span className="underline font-bold">Carta d'Identità Alimentare</span>® allora devi aggiornare il menù.</p>

            <button onClick={refreshMenu}
                className="my-2 w-11/12 flex items-center justify-center cursor-pointer py-3 px-8 rounded-md font-medium border-2 border-mycia-red">
                <p>Aggiorna il menù</p>
            </button>
            <div onClick={closerHandler} className="text-center">
                <span className="underline text-mycia-blue cursor-pointer">Non serve, non ho modificato niente</span>
            </div>
        </div>
    );
}

export default UpdateAccountModalContent;
