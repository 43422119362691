import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { useParams } from 'react-router';
import assets from '../utils/assets';

function BookingCompletedPage() {
    const { type, id } = useParams();

    return (
        <IonPage>
            <IonContent>
                <section id="success-page" className="relative pt-3 pb-32 font-sans text-center">
                    <p className="font-bold text-3xl">Prenota</p>
                    <img className="m-auto my-16" alt="Booking completed" src={assets.assetsPath("svg/booking_completed.svg")} />

                    <p className="font-semibold">Prenotazione inviata!</p>
                    <p className="font-thin">Riceverai una notifica via SMS quando il ristoratore risponderà alla tua richista</p>

                    <div className="fixed bottom-0 w-full flex flex-col justify-center mt-8 px-4 py-6 bg-white">
                        <a href={`/${type}/q/${id}/`} className="block py-2 rounded-lg shadow-lg bg-mycia-red text-white">TORNA ALL'INIZIO</a>
                    </div>
                </section>
            </IonContent>
        </IonPage>
    );
}

export default BookingCompletedPage;
