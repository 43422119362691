import React from "react";
import axios from "axios";
import formats from "../formats";
import { useFilters } from "./filters-context";
import { useRestaurant } from "./restaurant-context";

const UserContext = React.createContext();

function UserProvider(props) {
    const { restaurant, hasRestaurant } = useRestaurant();
    const { setFilters, resetFilters } = useFilters();

    const [user, setUser] = React.useState();
    const [cia, setCia] = React.useState();

    async function fetchUser() {
        return await axios({
            withCredentials: true,
            method: "get",
            url: `${process.env.REACT_APP_LOGIN_DOMAIN}/users/cia`,
            headers: {
                "Accept": "application/json",
            }
        });
    }

    async function attemptToLogin() {
        try {
            let response = await fetchUser();

            if (response.status === 200) {
                const { cia, ...user } = response.data.data;

                setUser(user);
                setCia(cia);
            }
        } catch (err) {
            console.error(err.response.status);
        }
    }

    async function logout(redirect) {
        try {
            await axios({
                withCredentials: true,
                method: "post",
                url: `https://login.mycia.it/logout?redirect=${encodeURIComponent(redirect)}`,
            });

            setUser(null);
            setCia(null);
        } catch (err) {
            console.error(err);
        }
    }

    React.useEffect(() => {
        attemptToLogin();
    }, []);

    React.useEffect(() => {
        if (cia) {
            if (hasRestaurant && restaurant.has_login) {
                setFilters(formats.legacyFilterFormatter(cia));
            } else {
                resetFilters();
            }
        } else {
            resetFilters();
        }
    }, [cia]);

    const value = {
        user,
        cia,
        logout,
    };
    return <UserContext.Provider value={value} {...props} />
}

function useUser() {
    const context = React.useContext(UserContext);
    if (!context) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
}

export { UserProvider, useUser }
