import { useCart } from "../../utils/contexts/cart-context";
import { useHistory } from "react-router";

function BucketHeader() {
    const history = useHistory();
    const { quantity } = useCart();

    const onClickHandler = () => {
        const urlParams = new URLSearchParams(window.location.search);
        let shareid = urlParams.get("shareid");
        if (shareid) {
            history.push(`orders/?shareid=${shareid}`);
        } else {
            history.push(`orders`);
        }
    };

    return (
        <button className="fixed-cart fixed right-0 m-6 px-3 py-1 rounded-full bg-mycia-green shadow-xl z-10" onClick={onClickHandler}>
            <div className="flex items-center space-x-1">
                {quantity ? <p className="font-bold">{quantity}</p> : null}
                <svg xmlns="http://www.w3.org/2000/svg" className="w-7" viewBox="0 0 512 512">
                    <circle cx="176" cy="416" r="16" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                    <circle cx="400" cy="416" r="16" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M48 80h64l48 272h256" />
                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M160 288h249.44a8 8 0 0 0 7.85-6.43l28.8-144a8 8 0 0 0-7.85-9.57H128" />
                </svg>
            </div>
        </button>
    );
}

export default BucketHeader;
