import { IonContent, IonIcon, IonPage } from '@ionic/react';
import { chevronBackOutline, people, person } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Openings from '../components/UI/Openings';
import { useRestaurant } from '../utils/contexts/restaurant-context';
import restaurantClient from '../logic/restaurant';
import formats from '../utils/formats';
import { useCart } from '../utils/contexts/cart-context';
import forms from '../utils/forms';
import Reviews from '../components/cart/Reviews';
import checkoutClient from '../logic/checkout';
import { useTracker } from '../utils/contexts/tracker-context';

function BookingRestaurantPage() {
    const history = useHistory();
    const { restaurant } = useRestaurant();
    const { type, id } = useParams();
    const { products, quantity, total, payed } = useCart();
    const { trackDataFn } = useTracker();

    const [minDate, setMinDate] = useState();
    const [minTime, setMinTime] = useState();
    const [correctDate, setcorrectDate] = useState();

    function onclickHandler(e) {
        e.preventDefault();
        history.push(formats.formatUrl("/:type/q/:id/menu", { "type": "b", "id": id }));
    }

    async function submitHandler(e) {
        e.preventDefault();
        if (correctDate) {
            let finalPrice = total;
            let amount = finalPrice * 100;

            const formData = Object.fromEntries(new FormData(e.target).entries());
            let localTotal = total + (restaurant.cover_charge * formData.seats);

            const payload = {
                "customer": formData.name + " " + formData.surname,
                "note": "",
                "phone": formData.phone,
                "reserved_at": formData.reserved_at,
                "reserved_time": formData.reserved_time,
                "seats": formData.seats,
                "token": restaurant.token,
            }

            if (quantity > 0) {
                payload.order = {};
                payload.order.restaurant = {
                    "id": restaurant.id,
                    "name": restaurant.name,
                    "phone": restaurant.phone_number,
                    "token": restaurant.token,
                };
                payload.order.order = {
                    "amount": amount,
                    "commissions": "",
                    "customers": formData.seats,
                    "id": checkoutClient.randomOrderID(),
                    "number": -1,
                    "payed": payed,
                    "products": products,
                    "quantity": quantity,
                    "timestamp": Date.now(),
                    "table": "-1",
                    "total": localTotal,
                    "type": "booking",
                    "note": "",
                };
                payload.order.transaction = {
                    "id": forms.generateRandomID("pending_"),
                    "provider": "pending",
                }
            }

            try {
                const res = await restaurantClient.doPostBooking(payload);
                if (res) {
                    await trackDataFn(restaurant.id, restaurant.token, "requestbooking", { "id": restaurant.id, "seats": formData.seats }, restaurant.has_orders);

                    history.push(formats.formatUrl("/:type/q/:id/bookingcompleted", { "type": type, "id": id }));
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        function timeCheck() {
            let f = new Intl.DateTimeFormat('en');
            let a = f.formatToParts();
            //console.log(a);
            //console.log(a[4].value + "-" + getMonth(a[0].value) + "-" + a[2].value);
            setMinDate(a[4].value + "-" + getMonth(a[0].value) + "-" + a[2].value);
            //console.log(new Date().getHours() + ":" + new Date().getMinutes())
            setMinTime(new Date().getHours() + ":" + new Date().getMinutes())
        }

        function getMonth(month) {
            return month < 10 ? '0' + month : '' + month; // ('' + month) for string result
        }

        timeCheck()
    }, []);

    function onChangeHandler(userTime) {
        userTime = new Date(userTime.target.value);
        if (userTime) {
            // setDate(userTime);
            let availableSlotTime = [];
            for (let day of restaurant.timetables) {
                if (formats.getDayOfWeek(day.day_of_week) === userTime.getDay()) {
                    availableSlotTime.push(day);
                }
            }
            if (availableSlotTime.length > 0) {
                setcorrectDate(true)
                return true;
            } else {
                // let index = 0;
                // for (let time of availableSlotTime) {
                //     let timeMin = new Date();
                //     let timeMax = new Date();

                //     timeMin.setMonth(userTime.getMonth());
                //     timeMin.setDate(userTime.getDate());
                //     timeMax.setMonth(userTime.getMonth());
                //     timeMax.setDate(userTime.getDate());

                //     timeMin.setHours(time.open[0] + time.open[1], time.open[3] + time.open[4], 0);
                //     timeMax.setHours(time.close[0] + time.close[1], time.close[3] + time.close[4], 0);

                //     console.log("---" + index + "---");
                //     console.log({ timeMin: timeMin });
                //     console.log({ timeMax: timeMax });
                //     console.log({ userTime: userTime });
                //     console.log("-------------");

                //     var nextWeek = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
                //     console.log({ nextWeek: nextWeek });
                //     if (userTime <= nextWeek) {
                //         // if (userTime >= timeMin && userTime <= timeMax) {
                //         //     // const { hours, minutes } = formats.shiftTime(new Date(), restaurant.orders.takeaway.medium_time);
                //         //     // let minimumTakeawayTime = new Date();
                //         //     // minimumTakeawayTime.setHours(hours, minutes);
                //         //     // if (userTime >= minimumTakeawayTime) {
                //         //     //     const { hours, minutes } = formats.shiftTime(userTime, 0);
                //         //     //     console.log({ hours, minutes });
                //         //     //     // setPayload(prevState => (
                //         //     //     //     { name: prevState.name, surname: prevState.surname, street: prevState.street, time: hours + ":" + minutes, phone: prevState.phone }));

                //         //     //     // setCorrectTime(true);
                //         //     //     console.log(true)
                //         //     //     return true;
                //         //     // }
                //         //     console.log(true);
                //         //     return true;
                //         // }
                //         setcorrectDate(true)
                //         return true;
                //     }
                //     index = index + 1;
                // }
                // setCorrectTime(false);
                setcorrectDate(false)
                return false;
            }
        }
    }

    return (
        <IonPage>
            <IonContent>
                <div className="p-4 relative overflow-y-scroll font-sans">
                    <div className="pb-4 flex pt-3 items-center">
                        <button type="button" onClick={() => history.push(formats.formatUrl("/:type/q/:id", { "type": type, "id": id }))} className="back-button absolute flex items-center justify-center p-3 rounded-full shadow-lg bg-white">
                            <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                        </button>

                        <h2 className="w-full text-center font-bold text-2xl">Prenota</h2>
                    </div>

                    <form onSubmit={submitHandler}>
                        <div className="">
                            <div className="flex py-1 justify-between items-center">
                                <div className="flex items-center">
                                    <IonIcon icon={person} className="mr-3" />
                                    <p className="pr-3 font-semibold">Nome</p>
                                </div>
                                <input type="text" name="name" id="name" className="w-1/2 mt-2 py-1 px-4 rounded-lg bg-grey-200" placeholder="Nome" required />
                            </div>

                            <div className="flex py-1 justify-between items-center">
                                <div className="flex items-center">
                                    <IonIcon icon={person} className="mr-3" />
                                    <p className="pr-3 font-semibold">Cognome</p>
                                </div>
                                <input type="text" name="surname" id="surname" className="w-1/2 mt-2 py-1 px-4 rounded-lg bg-grey-200" placeholder="Cognome" required />
                            </div>

                            <div className="flex py-6 justify-between items-center">
                                <div className="flex items-center">
                                    <IonIcon icon={people} className="mr-3" />
                                    <p className="pr-3 font-semibold">Numero coperti</p>
                                </div>
                                <input type="tel" name="seats" id="seats" min="1" className="w-1/4 p-1 text-center rounded-lg  bg-grey-200" required />
                            </div>

                            <div className="py-3 flex justify-between items-center">
                                <p className="font-bold">Data</p>
                                <input type="date" min={minDate} onChange={onChangeHandler} name="reserved_at" id="reserved_at" className="appearance-none p-1 w-2/5 h-8 text-center bg-grey-200 rounded-lg" required />
                            </div>

                            {(!correctDate) ? <p className="text-sm text-center text-red-600">Giorno non disponibile, controlla gli orari del locali</p> : null}

                            <div className="py-3 flex justify-between items-center">
                                <p className="font-bold">Orario</p>
                                <input type="time" min={minTime} name="reserved_time" id="reserved_time" className="appearance-none p-1 w-2/5 h-8 text-center bg-grey-200 rounded-lg" required />
                            </div>

                            <p className="py-3 font-bold">Orari di apertura del locale</p>
                            <Openings timetables={restaurant.timetables_formatted} />

                            <p className="pt-3 font-bold">Numero di telefono</p>
                            <div className="flex pb-3">
                                <input value="+39" className="float-left w-16 mt-2 py-1 px-2 rounded-lg bg-white" disabled />
                                <input type="tel" name="phone" id="phone" pattern="^((3[0-9][0-9]))(\d{7})$" className="float-left mt-2 py-1 px-2 rounded-lg bg-grey-200" placeholder="0000000000" required /*onChange={changeHandler}*/ />
                            </div>

                            <p className="text-sm">Il numero di telefono servirà solamente per confermare la prenotazione</p>
                        </div>

                        {products.length > 0 ?
                            <div className="mt-4 border-t border-b border-black">
                                <p className="pt-4 font-bold">Riepilogo</p>
                                <Reviews products={products} />
                            </div> :
                            <div className="pt-8 py-6">
                                <p className="pt-4 font-bold">Componi il tuo ordine</p>
                                <p className="text-sm">Vuoi già scegliere cosa mangiare ? Sfoglia il menù e invia il tuo ordine insieme alla prenotazione</p>
                                <button onClick={onclickHandler} className="button-native w-full p-2 py-3 mt-2 rounded-lg text-black border border-mycia-red">CREA IL TUO ORDINE</button>
                            </div>}

                        <div className="sticky bottom-0">
                            <div className="text-xs py-4">
                                <label className="text-left break-normal">Cliccando su "Prenota", accetti le condizioni di utilizzo di Carta d'Identità Alimentare. HealthyFood srl e il ristorante {restaurant.name}, in qualità di titolari del trattamento dei dati, utilizzano i dati personali degli utenti allo scopo di elaborare le prenotazioni e assicurare le comunciazioni con gli utenti</label>
                            </div>

                            <button type="submit" className="button-native flex items-center w-full p-2 py-3 mt-4 justify-center rounded-lg shadow-lg bg-mycia-red text-white">PRENOTA</button>
                        </div>
                    </form>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default BookingRestaurantPage;
