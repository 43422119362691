import { FILTERS } from './types';

function error(error) {
    return {
        "type": FILTERS.FILTERS_ERROR,
        "error": new Error(error),
    }
}

function success(filters) {
    return {
        "type": FILTERS.FILTERS_SUCCESS,
        "filters": filters,
    }
}

const actionsFilters = {
    error,
    success,
};
export default actionsFilters;
