import React from 'react';
import { IonReactRouter } from '@ionic/react-router';
import { UserProvider } from "./user-context";
import { RestaurantProvider } from "./restaurant-context";
import { PreferencesProvider } from './preferences-context';
import { CartProvider } from './cart-context';
import { FiltersProvider } from './filters-context';
import { TrackerProvider } from './tracker-context';
import { GroupsProvider } from './groups-context';

function AppProviders(props) {
    const { children } = props;

    return (
        <IonReactRouter>
            <RestaurantProvider>
                <GroupsProvider>
                    <TrackerProvider>
                        <PreferencesProvider>
                            <CartProvider>
                                <FiltersProvider>
                                    <UserProvider>
                                        {children}
                                    </UserProvider>
                                </FiltersProvider>
                            </CartProvider>
                        </PreferencesProvider>
                    </TrackerProvider>
                </GroupsProvider>
            </RestaurantProvider>
        </IonReactRouter>
    );
}

export default AppProviders;
