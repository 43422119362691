import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { useUser } from "../utils/contexts/user-context";
import { Link } from "react-router-dom";
import { useHistory, useParams } from 'react-router';
import { useQuery } from '../utils/contexts/query-hook';

function AuthCallback() {
    const query = useQuery();
    const history = useHistory();
    const { type, id } = useParams();
    const { authCallback, error, user } = useUser(history);

    React.useEffect(() => {
        authCallback(query.get("code"), type, id);
    }, []);

    React.useEffect(() => {
        history.push(`/${type}/q/${id}/menu`);
    }, [user]);

    return (
        <IonPage>
            <IonContent>
                <section id="auth-page" className="container mx-auto pt-12 px-8 font-sans">
                    <p>Authenticating user...</p>

                    {error ?
                        <div>
                            <p>Failed authorization. <Link to={`/${type}/q/${id}/menu`} className="underline">Go back</Link></p>
                            <p>{error.message}</p>
                        </div> :
                        null}
                </section>
            </IonContent>
        </IonPage>
    );
}

export default AuthCallback;
