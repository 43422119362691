import React from 'react';
import { useTranslation } from 'react-i18next';
import './DeliveryPopupModalContentStyle.css';

function DeliveryPopupModalContent(props) {
    const { onClickHandler } = props;

    const { t } = useTranslation();
    // const { restaurant } = useRestaurant();

    return (
        <div className="delivery-popup-modal-content overflow-auto text-black">
            <div className="px-8 py-8 text-center">
                <p>{t("deliverypopupmodalcontent.p_1.fragment_1")} <span className="text-mycia-green">{t("deliverypopupmodalcontent.p_1.span_1")} </span>{t("deliverypopupmodalcontent.p_1.fragment_2")}</p>
                {/* {restaurant.has_filter ?
            <React.Fragment>
                <p className="px-0">Hai qualche intolleranza o non ti piace qualcosa ?</p>
                <div className="pb-4"><FilterModalTrigger /></div>
            </React.Fragment>
            :
            null
        } */}
            </div>
            <div className="pt-2 pb-4 bg-mycia-green rounded-b-lg w-full">
                <button className="w-full" onClick={onClickHandler}>{t("deliverypopupmodalcontent.button")}</button>
            </div>
        </div>
    );
}

export default DeliveryPopupModalContent;
