import { IonContent, IonIcon, IonPage, useIonViewDidEnter } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useCart } from '../utils/contexts/cart-context';
import { useRestaurant } from '../utils/contexts/restaurant-context';
import formats from '../utils/formats';

function OrderTypePage() {
    const history = useHistory();
    const { id, type } = useParams();
    const { restaurant } = useRestaurant();

    const onBackHandler = () => history.push(`/${type}/q/${id}/orders`);
    const onClickHandler = () => history.push(`/${type}/q/${id}/orders/${orderType.url}`);

    const [orderType, setOrderType] = useState({ delivery: false, takeaway: false, shipment: false, url: null });
    const [enableOrderType, setEnableOrderType] = useState({ delivery: "border-solid border-l-8 border-transparent", takeaway: "border-solid border-l-8 border-transparent", shipment: "border-solid border-l-8 border-transparent" });
    const { total } = useCart();
    const [message, setMessage] = useState(null);

    let { minCost, maxCost } = formats.getDeliveryRangeCost(restaurant);

    useIonViewDidEnter(() => {
        if (orderType.delivery && total < restaurant.orders.delivery.minimum_price) {
            setMessage(`Il valore minimo per fare un ordine è di ${restaurant.orders.delivery.minimum_price}€`);
        } else {
            setMessage(null);
        }
    }, [orderType, total, restaurant]);

    useEffect(() => {
        if (orderType.delivery) {
            setEnableOrderType(prevState => (
                { delivery: "border-solid border-l-8 border-mycia-green font-bold", takeaway: prevState.takeaway, shipment: prevState.shipment }
            ));

        } else {
            setEnableOrderType(prevState => (
                { delivery: "border-solid border-l-8 border-transparent", takeaway: prevState.takeaway, shipment: prevState.shipment }
            ));
        }
        if (orderType.takeaway) {
            setEnableOrderType(prevState => (
                { delivery: prevState.delivery, takeaway: "border-solid border-l-8 border-mycia-green font-bold", shipment: prevState.shipment }
            ));
        } else {
            setEnableOrderType(prevState => (
                { delivery: prevState.delivery, takeaway: "border-solid border-l-8 border-transparent", shipment: prevState.shipment }
            ));
        }
        if (orderType.shipment) {
            setEnableOrderType(prevState => (
                { delivery: prevState.delivery, takeaway: prevState.takeaway, shipment: "border-solid border-l-8 border-mycia-green font-bold" }
            ));
        } else {
            setEnableOrderType(prevState => (
                { delivery: prevState.delivery, takeaway: prevState.takeaway, shipment: "border-solid border-l-8 border-transparent" }
            ));
        }

        if (orderType.delivery && total < restaurant.orders.delivery.minimum_price) {
            setMessage(`Il valore minimo per fare un ordine è di ${restaurant.orders.delivery.minimum_price}€`);
        }

    }, [orderType, restaurant.orders.delivery.minimum_price, total])

    function onChangeHandler(e) {
        if (e.target.name === "delivery") {
            setOrderType({ delivery: e.target.checked, takeaway: !e.target.checked, shipment: !e.target.checked, url: "delivery" })
            setMessage(null);
        }
        if (e.target.name === "takeaway") {
            setOrderType({ delivery: !e.target.checked, takeaway: e.target.checked, shipment: !e.target.checked, url: "takeaway" })
            setMessage(null);
        }
        if (e.target.name === "shipment") {
            setOrderType({ delivery: !e.target.checked, takeaway: !e.target.checked, shipment: e.target.checked, url: "shipment" })
            setMessage(null);
        }
    }

    function renderButtons() {
        if (!message) {
            if (orderType.url !== null) {
                return <button onClick={onClickHandler} className="block py-2 rounded-full bg-mycia-green">Conferma</button>
            } else {
                return <button onClick={onClickHandler} className="block py-2 text-grey-700 rounded-full bg-grey-400" disabled>Conferma</button>
            }
        } else {
            return <p className="text-center text-mycia-red">{message}</p>
        }
    }

    // useLayoutEffect(() => {
    //     async function pixelLog() {
    //         try {
    //             await window.fbq('track', 'ViewContent');
    //         } catch (e) {
    //             console.log({ pixelError: e })
    //         }
    //     }

    //     pixelLog();
    // }, [])

    return (
        <IonPage>
            <IonContent style={{ "--background": "#f0edec" }}>
                <div className="p-3">
                    <div className="flex items-center pt-6">
                        <button type="button" onClick={onBackHandler} className="back-button absolute flex items-center justify-center ml-4 mb-6 p-3 rounded-full shadow-lg bg-white">
                            <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                        </button>
                        <h2 className="pb-4 w-full text-center font-bold text-2xl">Tipologia ordine</h2>
                    </div>

                    <div className="mt-4 pb-6 rounded-lg bg-white">
                        <p className="py-6 text-center">Di che servizio hai bisogno ?</p>

                        <div className={`mt-4 mx-3 rounded-lg bg-grey-200 ${enableOrderType.delivery}`}>
                            <div className="flex-1 py-6 items-center" htmlFor="delivery">
                                <input type="radio" id="delivery" name="delivery" className="hidden" onClick={onChangeHandler} />
                                <label className="py-10 pl-8" style={{ "padding-right": "5.5rem" }} htmlFor="delivery">{process.env.REACT_APP_NAMESPACE === "shop" ? "Consegna in città" : "Consegna a domicilio"}</label>
                                {(Number(minCost) !== 0 && Number(maxCost) !== 0 && minCost !== null && maxCost !== null && minCost !== undefined && maxCost !== undefined) &&
                                    <label className="block items-center pl-8 pr-3 text-xs" htmlFor="delivery">
                                        {restaurant.has_pony_u ?
                                            `Costo consegna: ${(minCost)}€` :
                                            `Costo consegna: costo minimo ${(minCost)}€, costo massimo ${(maxCost)}€`}
                                    </label>}
                            </div>
                        </div>
                        <div className={`mt-4 mx-3 rounded-lg bg-grey-200 ${enableOrderType.takeaway}`}>
                            <div className="flex-1 py-6 items-center" htmlFor="takeaway">
                                <input type="radio" id="takeaway" name="takeaway" className="hidden" onClick={onChangeHandler} />
                                <label className="py-8 pl-8" style={process.env.REACT_APP_NAMESPACE === "shop" ? null : { "padding-right": "18rem" }} htmlFor="takeaway">{process.env.REACT_APP_NAMESPACE === "shop" ? "Ordina e passa a ritirare" : "Takeaway"}</label>
                            </div>
                        </div>
                        {"shop" === process.env.REACT_APP_NAMESPACE && <div className={`mt-4 mx-3 rounded-lg bg-grey-200 ${enableOrderType.shipment}`}>
                            <div className="flex-1 py-6 items-center" htmlFor="shipment">
                                <input type="radio" id="shipment" name="shipment" className="hidden" onClick={onChangeHandler} />
                                <label className="py-8 pl-8" style={{ "padding-right": "18rem" }} htmlFor="shipment">Spedisci</label>
                            </div>
                        </div>}
                    </div>
                </div>

                <div className="w-full flex flex-col justify-center mt-8 p-4">
                    {renderButtons()}
                </div>
            </IonContent>
        </IonPage>
    );
}

export default OrderTypePage;
