import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import AllergenIcons from '../UI/AllergenIcons';
import Cta from '../cart/Cta';
import DrinkModalTrigger from '../modals/DrinkModalTrigger';
import PreferredButton from '../UI/PreferredButton';
import assets from '../../utils/assets';
import formats from '../../utils/formats';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import { useTracker } from '../../utils/contexts/tracker-context';
import parse from "html-react-parser";

function Drink(props) {
    const { drink } = props;

    const { restaurant } = useRestaurant();
    const { type } = useParams();
    const { trackDataFn } = useTracker();

    const [showDrinkModal, setShowDrinkModal] = useState(false);
    const [description, setDescription] = useState("");

    let icons = [];
    let name = formats.formatDrinkName(drink);

    (drink.has_sulfites) && icons.push(assets.assetsPath("images/icons/allergie/solfiti.png"));

    useEffect(() => {
        setDescription(parse(formats.formatDescription(drink).replace(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g, formats.replacer)))
    }, [drink]);

    const onDetailsOpenHandler = async function handleClick() {
        await trackDataFn(restaurant.id, restaurant.token, "drinkview", { "type": type, "name": drink.name, "is_campari": drink.is_campari, "id": drink.id, "price": drink.price }, restaurant.has_orders);
        // try {
        //     await window.fbq("track", "ViewContent");
        // } catch (e) {
        //     console.log({ pixelError: e })
        // }
        // finally {
        setShowDrinkModal(true);
        // }
    };

    return (
        <div className="drink p-2">
            <div className="drink-content flex flex-col rounded-lg shadow bg-grey-100">
                <div className="flex">
                    <div className="flex-1 py-2 px-4">
                        <p className="drink-content-name font-semibold">{name}</p>
                        {String(restaurant.id) === "9440" || String(restaurant.id) === "10158" ?
                            formats.formatMotuekaDescription(drink.description).map(d =>
                                <p className="text-sm">{d}</p>) :
                            <p className="text-sm">{description}</p>}

                        <AllergenIcons icons={icons} /> {formats.shouldDisplayDrinkDetails(drink) ? <DrinkModalTrigger drink={drink} showDrinkModal={showDrinkModal} setShowDrinkModal={setShowDrinkModal} onDetailsOpenHandler={onDetailsOpenHandler} /> : null}
                    </div>
                    <div className="flex flex-col items-end py-2 pr-4 text-base">
                        <PreferredButton item={drink} drink={true} />

                        <p className="mt-2 font-bold">{formats.formatPrice(drink.price)}</p>
                        <p className="mt-2 font-bold">{Number(drink.glass_price) !== 0 ? "Calice: " + formats.formatPrice(drink.glass_price) : ""}</p>

                        {(typeof drink.image_path === "string" && drink.image_path !== "") ? <img className="object-cover w-26 h-26 my-3 border border-grey-300 rounded-lg" src={drink.image_path} onClick={onDetailsOpenHandler} alt="drink"></img> : null}
                    </div>
                </div>
                {formats.shouldDisplayCta(type, restaurant.orders) ? <Cta product={drink} /> : null}
            </div>
        </div>
    );
}

export default Drink;
