import axios from 'axios';
import formats from '../utils/formats';

export const DOMAIN = "https://api-ristoratori-v3.mycia.it";

async function doGetGroup(name) {
    const PATH = "/groups/:name"

    let response = await axios.get(formats.formatUrl(DOMAIN + PATH, { "name": name }));

    return response && response.data;
}

async function doGetGroupRestaurants(name) {
    const PATH = "/restaurants?city=:name&pacchetti=3,4,5&randomize=1";

    let response = await axios.get(formats.formatUrl(DOMAIN + PATH, { "name": name }));

    return response && response.data;
}

async function searchOnGroups(isShop, lat, lng, type, search, groupName) {
    const DISH = "https://app.mycia.it/api/v1/restaurants/search/plate?latitude=:lat&longitude=:lng&filter_range_meters=30000&:type=:search&limit=1000";
    const WINE = "https://app.mycia.it/api/v1/restaurants/search/wine?latitude=:lat&longitude=:lng&filter_range_meters=30000&:type=:search&limit=1000";

    let response;

    if (!isShop) {
        if (type === "food_type") {
            response = await axios.get(formats.formatUrl(DISH, { lat, lng, type, search }));
        } else {
            response = await axios.get(formats.formatUrl(WINE, { lat, lng, type, search }));
        }
    } else {
        response = await axios.get(formats.formatUrl(DISH, { lat, lng, type: "food_type", search }));
    }

    let restaurantsGroup = await doGetGroupRestaurants(groupName);
    let restaurantsBar = restaurantsGroup.filter((r) => r.category === "restaurant");
    let shops = restaurantsGroup.filter((r) => r.category === "shop");

    let restaurantsGroupFiltered = [];

    if (isShop) {
        for (let i = 0; i < shops.length; i++) {
            for (let j = 0; j < response.data.response.data.length; j++) {
                if (shops[i].name.toString() === response.data.response.data[j].name.toString()) {
                    restaurantsGroupFiltered.push(response.data.response.data[j]);
                }
            }
        }
    } else {
        for (let i = 0; i < restaurantsBar.length; i++) {
            for (let j = 0; j < response.data.response.data.length; j++) {
                if (restaurantsBar[i].name.toString() === response.data.response.data[j].name.toString()) {
                    restaurantsGroupFiltered.push(response.data.response.data[j]);
                }
            }
        }
    }

    return restaurantsGroupFiltered;
}

async function searchOnGroupsForShop(lat, lng, type, search, groupName) {
    const DISH = "https://app.mycia.it/api/v1/restaurants/search/plate?latitude=:lat&longitude=:lng&filter_range_meters=30000&:type=:search&limit=1000";
    const WINE = "https://app.mycia.it/api/v1/restaurants/search/wine?latitude=:lat&longitude=:lng&filter_range_meters=30000&:type=:search&limit=1000";

    let response = await axios.get(formats.formatUrl(DISH, { lat, lng, type: "food_type", search }));
    let restaurantsGroup = await doGetGroupRestaurants(groupName);

    let shops = restaurantsGroup.filter((r) => r.category === "shop");
    let restaurantsGroupFiltered = [];

    for (let i = 0; i < shops.length; i++) {
        for (let j = 0; j < response.data.response.data.length; j++) {
            if (shops[i].name.toString() === response.data.response.data[j].name.toString()) {
                restaurantsGroupFiltered.push(response.data.response.data[j]);
            }
        }
    }

    return restaurantsGroupFiltered;
}

const groupsClient = {
    doGetGroup,
    doGetGroupRestaurants,
    searchOnGroups,
};
export default groupsClient;
