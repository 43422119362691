export default function Nutrients(props) {
    const { nutrients } = props;

    return (
        <dl>
            {Object.entries(nutrients).map((nutrient, index) => {
                return (
                    <div className="flex justify-between" key={index}>
                        <dt className="capitalize">{nutrient[0].replace("_", " ")}</dt>
                        <dd>{nutrient[1]}<span className="text-sm text-grey-600">g / 100g</span></dd>
                    </div>
                );
            })}
        </dl>
    );
}
