import React, { useLayoutEffect, useState } from "react";
import { useParams } from "react-router";
import { IonContent, IonPage } from "@ionic/react";
import { useTracker } from "../utils/contexts/tracker-context";
import { useRestaurant } from "../utils/contexts/restaurant-context";
import RestaurantLoader from "../components/containers/RestaurantLoader";
import UltraBrasserieSplash from "../components/UI/UltraBrasserieSplash";
import theme from "../utils/theme";

function UltraBrasserieSplashPage(props) {
    const { trackDataFn } = useTracker();

    const [palette, setPalette] = useState("");

    const { type } = useParams();
    const { restaurant } = useRestaurant();

    useLayoutEffect(() => {
        if (restaurant) {
            if (!process.env.REACT_APP_THEME) {
                theme.getTheme(restaurant.id, setPalette);
            } else {
                setPalette(process.env.REACT_APP_THEME);
            }
        }

        async function track() {
            restaurant && await trackDataFn(restaurant.id, restaurant.token, "usersession", { "type": type }, restaurant.has_orders);
        }

        track();
    }, [restaurant, trackDataFn, type]);

    return (
        <IonPage>
            <IonContent style={theme.getThemeIonComponents(palette).splashPage}>
                <section id="splash-page" className="font-sans">
                    <RestaurantLoader component={UltraBrasserieSplash} {...props} />
                </section>
            </IonContent>
        </IonPage>
    );
}

export default UltraBrasserieSplashPage;
