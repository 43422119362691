import React, { useState, useEffect } from 'react';
import translationModalClient from '../../logic/translationModal';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import dl from '../../utils/device-language'
import formats from '../../utils/formats'
import { close } from 'ionicons/icons';
import './TranslationModalContentStyle.css';

function TranslationModalContent(props) {
    const { dish, type, closerHandler } = props;

    const [isEnable, setIsEnable] = useState(false);
    const [translation, setTranslation] = useState("");
    const [lang, setLang] = useState("");

    const { t } = useTranslation();

    let classNames = "py-2 px-4 border border-blue-400 w-full rounded-full";

    useEffect(() => {
        async function fetchData() {
            const response = await translationModalClient.doGet();
            setLang(response.response[0]);
        };

        fetchData();
    }, [])

    function changeHandler(e) {
        if (e.target.value !== "") {
            setIsEnable(true);
            setTranslation(e.target.value);
        } else {
            setIsEnable(false);
        }
    };

    async function onClickHandler() {
        const payload = {
            id: dish.id,
            text: translation,
            lang: dl.getDeviceLanguage(),
            lang_id: lang.id,
            type: type,
        }

        translationModalClient.doPost(payload);
        closerHandler();
    };

    return (
        <div className="translation-modal-content overflow-auto text-black">
            <div className="relative mb-4 px-4" style={{ "minHeight": "2rem" }}>
                <button type="button" onClick={closerHandler} className="z-10 absolute text-2xl top-0 right-0 flex items-center justify-center mt-4 p-3 pb-0">
                    <IonIcon slot="icon-only" icon={close}></IonIcon>
                </button>
            </div>

            <div className="pb-4 p-3 px-8">
                <p className="p-3 border-b border-b-gray-600">{t("translationmodalcontent.p_1.fragment_1")} <span className="font-bold">{lang.name}</span>. {t("translationmodalcontent.p_1.fragment_2")}</p>
                <p className="mt-6">{t("translationmodalcontent.p_2")}</p>
                <p className="font-bold">{formats.capitalize(dish.name)}</p>
                <div className="pt-3">
                    <input type="text" name="input" id="input" className="w-full mt-2 py-3 px-4 rounded-lg bg-grey-200" placeholder="Scrivi la tua correzione" onChange={changeHandler} />
                </div>
                <div className="pt-6">
                    <button onClick={onClickHandler} disabled={!isEnable} className={isEnable ? `${classNames}` : `${classNames} opacity-25 cursor-not-allowed`}>{t("translationmodalcontent.button_1")}</button>
                </div>
            </div>
        </div>
    );
}

export default TranslationModalContent;
