import actionsTypes from "../actions/types";

export const defaultState = {
    "filters": { allergies: null, foods: null, diets: null },
    "error": null,
};

function reducer(state, action) {
    switch (action.type) {
        case actionsTypes.FILTERS_SUCCESS: {
            return {
                ...state,
                "filters": action.filters,
            }

        }
        case actionsTypes.FILTERS_ERROR: {
            return {
                ...state,
                "filters": { allergies: null, foods: null, diets: null },
                "error": action.error,
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export default reducer;
