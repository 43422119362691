import axios from 'axios';
import formats from '../utils/formats';

export const CHECKOUTENDPOINT = "https://api-ristoratori-v3.mycia.it/sumup/checkout/?amount=:amount&currency=:currency&pay_to_email=:pay_to_email&description=:description"

// only checkout id beacuse the token and other data are directly handled by the server
async function doGetCheckout(amount, currency, payToEmail, description) {
    var response = await axios.get(formats.formatUrl(CHECKOUTENDPOINT, { "amount": amount, "currency": currency, "pay_to_email": payToEmail, "description": description }));

    return response && response.data;
}

const sumUpClient = {
    doGetCheckout,
};
export default sumUpClient;
