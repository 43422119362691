import React from "react";
import { IonIcon, IonModal } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { shuffle } from "ionicons/icons";
import FilterModalContent from "./FilterModalContent";

export default function FilterModalTrigger(props) {
    const { t } = useTranslation();

    const [showModal, setShowModal] = React.useState(false);

    const onOpenHandler = () => setShowModal(true);
    const onCloseHandler = () => setShowModal(false);

    return (
        <React.Fragment>
            <div onClick={onOpenHandler} className="filter-modal-trigger cursor-pointer button-native w-auto py-2 px-4 flex justify-center items-center rounded-full shadow-lg bg-mycia-red text-white">
                <p>{t("filtermodaltrigger.button_1")}</p>

                <IonIcon icon={shuffle} className="pl-3" />
            </div>

            <IonModal isOpen={showModal} onDidDismiss={onCloseHandler}>
                <FilterModalContent closeHandler={onCloseHandler} />
            </IonModal>
        </React.Fragment>
    );
}
