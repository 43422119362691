import React from 'react';
import restaurantClient from '../../logic/restaurant';
import restaurantReducer, { defaultState } from '../reducers/restaurant-reducer';
import actionsRestaurant from '../actions/restaurant';
import dl from '../device-language';
import formats from '../formats';

const RestaurantContext = React.createContext();

function RestaurantProvider(props) {
    const [state, setState] = React.useReducer(restaurantReducer, defaultState);

    const { isLoading, restaurant, error, search } = state;

    const run = React.useCallback(async (promise, search) => {
        if (search || search === "") { //TODO: check
            const restaurant = await promise;
            const searchedPayload = await restaurantClient.doGetSearch("it", restaurant.id, search);

            formats.setSearchedItems(restaurant, searchedPayload, search);
        }
        if ("morgana" === process.env.REACT_APP_NAMESPACE || "mycia" === process.env.REACT_APP_NAMESPACE) {
            promise.then(restaurant => {
                if (String(restaurant.id) === "9207") {
                    formats.setMorganaMenu(restaurant)
                }
            })
        } else if ("redcafe" === process.env.REACT_APP_NAMESPACE) {
            promise.then(restaurant => (
                formats.setRedCafeMenu(restaurant)
            ))
        }

        return promise.then(
            restaurant => setState(actionsRestaurant.success(restaurant)),
            error => setState(actionsRestaurant.error(error))
        );
    }, []);

    const hasRestaurant = Boolean(restaurant);
    const setRestaurant = React.useCallback(async id => {
        if (!hasRestaurant) {
            setState(actionsRestaurant.fetching(id));
            await run(restaurantClient.doGet(dl.getApiLanguage(), id));
        }
    }, [run, hasRestaurant]);
    const setSearch = React.useCallback(async search => {
        setState(actionsRestaurant.searching(search));
        await run(restaurantClient.doGet(dl.getApiLanguage(), restaurant.id), search);
    }, [restaurant, run]);

    const value = React.useMemo(() => {
        return {
            error,
            isLoading,
            restaurant,
            hasRestaurant,
            search,
            setRestaurant,
            setSearch,
        }
    }, [error, isLoading, restaurant, search, setRestaurant, setSearch]);

    return <RestaurantContext.Provider value={value} {...props} />;
}

function useRestaurant() {
    const context = React.useContext(RestaurantContext);
    if (!context) {
        throw new Error("useRestaurant must be used within a RestaurantProvider");
    }
    return context;
}

export { RestaurantProvider, useRestaurant }
