import { PREFERENCES } from "./types";

function add(product) {
    return {
        "type": PREFERENCES.PREFERENCES_ADD,
        "payload": product,
    }
}

function remove(productId) {
    return {
        "type": PREFERENCES.PREFERENCES_REMOVE,
        "id": productId,
    }
}

const actionsPreferences = {
    add,
    remove,
};
export default actionsPreferences;
