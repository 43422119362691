import React, { useEffect, useState } from 'react';

function SpoilerButton(props) {
    const { content } = props;

    const [summary, setSummary] = useState(false);
    const [splitContent, setSplitContent] = useState(content);
    const [hideContent, setHideContent] = useState();

    useEffect(() => {
        if (content.length > 189) {
            setSplitContent(content.slice(0, 189));
            setSplitContent(cont => cont.concat("..."));
            setHideContent(content.slice(189));
            setSummary(true);
        }
    }, [content])


    return (
        <>
            <p className="mt-2">{splitContent}</p>
            {summary ?
                <div>
                    <details>
                        <summary>Continua a leggere</summary>
                        {hideContent}
                    </details>
                </div>
                : null
            }
        </>
    )
}

export default SpoilerButton;
