import { CART } from './types';

function add(product, quantity = 1) {
    return {
        "type": CART.CART_ADD,
        "payload": product,
        "quantity": quantity,
    }
}

function remove(productId) {
    return {
        "type": CART.CART_REMOVE,
        "id": productId,
    }
}

function addOne(productId) {
    return {
        "type": CART.CART_ADDONE,
        "id": productId,
    }
}

function subOne(productId) {
    return {
        "type": CART.CART_SUBONE,
        "id": productId,
    }
}

function clean() {
    return {
        "type": CART.CART_CLEAN,
    }
}

function setTable(table) {
    return {
        "type": CART.CART_TABLE,
        "table": table,
    }
}

function setNumber(number) {
    return {
        "type": CART.CART_NUMBER,
        "number": number,
    }
}

function setPayed(bool) {
    return {
        "type": CART.CART_PAYED,
        "payed": bool,
    }
}

function setType(type) {
    return {
        "type": CART.CART_TYPE,
        "order_type": type,
    }
}

function addNote(productId, note) {
    return {
        "type": CART.CART_ADDNOTE,
        "id": productId,
        "note": note,
    }
}

function addOrderNote(orderNote) {
    return {
        "type": CART.CART_ADDORDERNOTE,
        "order_note": orderNote,
    }
}

function setCustomers(number) {
    return {
        "type": CART.CART_CUSTOMERS,
        "customers_number": number,
    }
}

function setCustomer(customer) {
    return {
        "type": CART.CART_CUSTOMER,
        "customer": customer,
    }
}

function setDeliveryCost(deliveryCost) {
    return {
        "type": CART.CART_DELIVERY,
        "deliveryCost": deliveryCost,
    }
}

const actionsCart = {
    add,
    remove,
    addOne,
    subOne,
    clean,
    setTable,
    setNumber,
    setPayed,
    setType,
    addNote,
    addOrderNote,
    setCustomers,
    setCustomer,
    setDeliveryCost,
};
export default actionsCart;
