import React from 'react';
import List from './List';
import Menu from './Menu';
import Shop from './Shop';

function Fork(props) {
    const { restaurant } = props;

    if ("shop" === process.env.REACT_APP_NAMESPACE) {
        return <Shop shop={restaurant} />;
    }

    return (restaurant.has_orders) ?
        (
            <List restaurant={restaurant} />
        ) : (
            <Menu restaurant={restaurant} />
        );
}

export default Fork;
