import axios from 'axios';
import formats from '../utils/formats';

export const ENDPOINT = "https://api-ristoratori-v3.mycia.it/menu/sharing/:token";
export const KEY = "https://api-ristoratori-v3.mycia.it/menu/sharing/token";
export const PUTENDPOINT = "https://api-ristoratori-v3.mycia.it/menu/sharing/";

async function doGetNewShareId() {
    var response = await axios.get(KEY);

    return response && response.data;
}

async function doGet(shareId) {
    var response = await axios.get(formats.formatUrl(ENDPOINT, { "token": shareId }));

    return response && response.data;
}

async function doPut(payload) {
    var data = JSON.stringify({
        "res_id": payload.resId,
        "type": payload.type,
        "order": payload.order,
        "token": payload.token,
    });
    var config = {
        method: 'put',
        url: PUTENDPOINT,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    try {
        const response = await axios(config);
        return response && response.data;
    } catch (e) {
        return e.response && e.response.data;
    }

}

const cartSharingClient = {
    doGetNewShareId,
    doGet,
    doPut,
};
export default cartSharingClient;
