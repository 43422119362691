import axios from 'axios';
import formats from '../utils/formats';

export const ENDPOINT = "https://orders.mycontactlessmenu.mycia.it/api/tables/status?token=:token&name=:name";

async function doGet(token, name) {
    var response = await axios.get(formats.formatUrl(ENDPOINT, { "token": token, "name": name }));

    return response && response.data;
}

const tablesClient = {
    doGet,
};
export default tablesClient;
