// auth
const AUTH_ERROR = "AUTH_ERROR";
const AUTH_FETCHING = "AUTH_FETCHING";
const AUTH_LOGOUT = "AUTH_LOGOUT";
const AUTH_SUCCESS = "AUTH_SUCCESS";

export const AUTH = {
    AUTH_ERROR,
    AUTH_FETCHING,
    AUTH_LOGOUT,
    AUTH_SUCCESS,
}

// cart
const CART_ADD = "CART_ADD";
const CART_ADDONE = "CART_ADDONE";
const CART_REMOVE = "CART_REMOVE";
const CART_SUBONE = "CART_SUBONE";
const CART_CLEAN = "CART_CLEAN";
const CART_TABLE = "CART_TABLE";
const CART_NUMBER = "CART_NUMBER";
const CART_TYPE = "CART_TYPE";
const CART_ADDNOTE = "CART_ADDNOTE";
const CART_ADDORDERNOTE = "CART_ADDORDERNOTE";
const CART_CUSTOMERS = "CART_CUSTOMERS";
const CART_CUSTOMER = "CART_CUSTOMER";
const CART_DELIVERY = "CART_DELIVERY";
const CART_PAYED = "CART_PAYED";

export const CART = {
    CART_ADD,
    CART_ADDONE,
    CART_REMOVE,
    CART_SUBONE,
    CART_CLEAN,
    CART_TABLE,
    CART_NUMBER,
    CART_TYPE,
    CART_ADDNOTE,
    CART_ADDORDERNOTE,
    CART_CUSTOMERS,
    CART_CUSTOMER,
    CART_DELIVERY,
    CART_PAYED,
}

// restaurant
const RESTAURANT_ERROR = "RESTAURANT_ERROR";
const RESTAURANT_FETCHING = "RESTAURANT_FETCHING";
const RESTAURANT_SUCCESS = "RESTAURANT_SUCCESS";
const RESTAURANT_SEARCHING = "RESTAURANT_SEARCHING";

export const RESTAURANT = {
    RESTAURANT_ERROR,
    RESTAURANT_FETCHING,
    RESTAURANT_SUCCESS,
    RESTAURANT_SEARCHING,
}

// preferences
const PREFERENCES_ADD = "PREFERENCES_ADD";
const PREFERENCES_REMOVE = "PREFERENCES_REMOVE";

export const PREFERENCES = {
    PREFERENCES_ADD,
    PREFERENCES_REMOVE,
}

// filters
const FILTERS_ERROR = "FILTERS_ERROR";
const FILTERS_SUCCESS = "FILTERS_SUCCESS";

export const FILTERS = {
    FILTERS_ERROR,
    FILTERS_SUCCESS,
}

const actionsTypes = {
    AUTH_ERROR,
    AUTH_FETCHING,
    AUTH_LOGOUT,
    AUTH_SUCCESS,
    CART_ADD,
    CART_ADDONE,
    CART_REMOVE,
    CART_SUBONE,
    CART_CLEAN,
    CART_TABLE,
    CART_NUMBER,
    CART_TYPE,
    CART_ADDNOTE,
    CART_ADDORDERNOTE,
    CART_CUSTOMERS,
    CART_PAYED,
    CART_CUSTOMER,
    CART_DELIVERY,
    RESTAURANT_ERROR,
    RESTAURANT_FETCHING,
    RESTAURANT_SUCCESS,
    RESTAURANT_SEARCHING,
    PREFERENCES_ADD,
    PREFERENCES_REMOVE,
    FILTERS_ERROR,
    FILTERS_SUCCESS,
};
export default actionsTypes;
