import { IonModal } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import RedBullModalContent from './RedBullModalContent';
import "./RedBullModalContentStyle.css"

function RedBullModalTrigger(props) {
    const { setCouponSentinel } = props;

    const [showModal, setShowModal] = useState(false);

    const onCloseHandler = () => setShowModal(false);

    useEffect(() => {
        setTimeout(() => setShowModal(true), 1000);
    }, []);

    return (
        <IonModal cssClass="redbull-modal-content" mode="md" isOpen={showModal} onDidDismiss={onCloseHandler}>
            <RedBullModalContent setCouponSentinel={setCouponSentinel} onClickHandler={onCloseHandler} />
        </IonModal>
    );
}

export default RedBullModalTrigger;
