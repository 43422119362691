import React from "react";
import { useTranslation } from "react-i18next";
import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import assets from "../../utils/assets";

import "./LegendModalContentStyle.css";

export default function LegendModalContent(props) {
    const { closeHandler } = props;

    const { t } = useTranslation();

    return (
        <div className="legend-modal-content overflow-y-scroll text-black">
            <div className="mb-4 px-2 flex justify-between">
                <span></span>

                <button type="button" onClick={closeHandler} className="flex items-center justify-center mt-2 p-2 rounded-full shadow-lg bg-white">
                    <IonIcon slot="icon-only" icon={close}></IonIcon>
                </button>
            </div>

            <div className="py-3 overflow-y-auto text-sm text-black">
                <p className="font-extrabold text-2xl text-center text-mycia-red">{t("filterslegendmodalcontent.p_1")}</p>
                <p className="p-3 text-base text-center text-mycia-medium">{t("filterslegendmodalcontent.p_2")}</p>

                <ul className="px-3 text-mycia-medium">
                    <li className="py-4 flex items-center">
                        <img src={assets.assetsPath("images/icons/menu/ic_tick_gray.png")} alt="icons legend" className="flex-none w-8" />

                        <p className="ml-8">{t("filterslegendmodalcontent.li_1.fragment_1")}<span className="font-bold text-mycia-red"> {t("filterslegendmodalcontent.li_1.span_1")}</span>).</p>
                    </li>
                    <li className="py-4 flex items-center">
                        <img src={assets.assetsPath("images/icons/menu/ic_cross_gray.png")} alt="icons legend" className="flex-none w-8" />

                        <p className="ml-8">{t("filterslegendmodalcontent.li_2.fragment_1")}<span className="font-bold text-red-600"> {t("filterslegendmodalcontent.li_2.span_1")}</span>).</p>
                    </li>
                    <li className="py-4 flex items-center">
                        <img src={assets.assetsPath("images/icons/menu/ic_cross_yellow.png")} alt="icons legend" className="flex-none w-8" />

                        <p className="ml-8">{t("filterslegendmodalcontent.li_3")}</p>
                    </li>
                    <li className="py-4 flex items-center">
                        <img src={assets.assetsPath("images/icons/menu/ic_triangle_gray.png")} alt="icons legend" className="flex-none w-8" />

                        <p className="ml-8">{t("filterslegendmodalcontent.li_4.fragment_1")} <span className="font-bold text-black">{t("filterslegendmodalcontent.li_4.span_1")}</span>).</p>
                    </li>
                </ul>

                <p className="pt-4 pb-2 text-sm text-center">Le seguenti icone si intendono come indicative, occorre sempre chiedere conferma al locale sugli ingredienti presenti nel piatto per verificarne la compatibilità.</p>
            </div>
        </div>
    );
}
