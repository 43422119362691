import React, { useLayoutEffect } from 'react';
import { IonModal } from '@ionic/react';
import CiaLoginModalContent from './CiaLoginModalContent';

function CiaLoginModalTrigger({ showModal, setShowModal }) {
    const onCloseHandler = () => setShowModal(false);

    useLayoutEffect(() => {
        setTimeout(() => setShowModal(true), 1200);
    }, []);

    return (
        <div className="cia-login-modal-trigger py-1">
            <IonModal cssClass="cia-login-modal-trigger" mode="md" isOpen={showModal} onDidDismiss={onCloseHandler}>
                <CiaLoginModalContent closerHandler={onCloseHandler} />
            </IonModal>
        </div>
    );
}

export default CiaLoginModalTrigger;
