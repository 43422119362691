import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IonIcon } from "@ionic/react";
import parse from "html-react-parser";
import dl from "../../utils/device-language";
import formats from "../../utils/formats";
import { useUser } from "../../utils/contexts/user-context";
import { useFilters } from "../../utils/contexts/filters-context";
import { useRestaurant } from "../../utils/contexts/restaurant-context";
import { close, warning } from "ionicons/icons";
import assets from "../../utils/assets";
import IngredientList from "../UI/IngredientList";
import Nutrients from "./Nutrients";

export default function DishModalContent(props) {
    const { dish, closerHandler } = props;

    const { t } = useTranslation();
    const { restaurant } = useRestaurant();
    const { filtered } = useFilters();
    const { user } = useUser();

    const [description, setDescription] = useState(dish.description);

    useEffect(() => {
        (String(restaurant.id) === "6608" || String(restaurant.id) === "13040") &&
            setDescription(parse(dish.description_raw))
    }, []);

    return (
        <div className="dish-modal-content mx-auto pt-8 pb-16 overflow-y-scroll text-black">
            <div className="mb-4 px-2 flex justify-between">
                <span></span>

                <button type="button" onClick={closerHandler} className="flex items-center justify-center mr-3 mt-2 p-2 rounded-full shadow-lg bg-white">
                    <IonIcon slot="icon-only" icon={close}></IonIcon>
                </button>
            </div>

            {dish.has_video ?
                <iframe src={dish.video_url} frameborder="0" allow="autoplay; encrypted-media" title="video" allowfullscreen></iframe> :
                (dish.image_path && "" !== dish.image_path) ?
                    <img src={dish.image_path} className="object-cover w-full mb-4 shadow rounded-lg" alt={t("dishmodalcontent.img_1")} /> :
                    null}

            <div className="py-4 px-8">
                <p className="mb-6 py-4 font-medium text-2xl border-b border-b-gray-600">{formats.capitalize(dish.name)}</p>

                {dish.description.length && (dl.isCleanDeviceLanguageV2("it-IT") || dl.isCleanDeviceLanguageV2("it")) ?
                    <p className="pb-6 whitespace-pre-wrap">{description}</p> : null}

                <p className="mb-4 font-medium">{t("dishmodalcontent.p_1")}</p>

                <ul className="ingredients-list mb-6 list-disc list-inside">
                    {dish.ingredients.map((ingredient, index) => <IngredientList ingredient={ingredient} key={index} />)}

                    {dish.has_sulfites ?
                        <li>
                            <span className="font-bold">{t("allergens.elements.Solfiti")}</span>
                            <img src="assets/images/icons/allergie/solfiti.png" className="inline w-5 ml-2" alt="solfiti" />
                        </li> : null}
                </ul>

                {dish.has_nutritional_values ?
                    <div>
                        <p className="font-bold">Valori nutrizionali</p>

                        <Nutrients nutrients={dish.nutritional_values}></Nutrients>
                    </div> : null}

                {formats.shouldDisplayFilterIcon(dish) ?
                    <div className="flex items-center justify-center py-6 px-4 border-t border-t-gray-200">
                        <img src={assets.assetsPath(dish.icon)} className="flex-none w-8 mr-4 fill-current text-mycia-red" alt="" />

                        <p className="text-sm">{dish.user_suggestion}</p>
                    </div> : null}

                {dish.has_allergens ?
                    <div className="allergens-warning flex items-center justify-center py-6 px-4 border-t border-t-gray-200">
                        <IonIcon icon={warning} size="large" className="flex-none mr-4 fill-current text-mycia-red"></IonIcon>

                        <p className="text-sm"><span>{t("dishmodalcontent.p_2.span_1")}</span> ({t("dishmodalcontent.p_2.span_2")} <span className="text-mycia-red">{t("dishmodalcontent.p_2.fragment_1")}</span>).</p>
                    </div> : null}

                {dish.is_frozen ?
                    <div className="frozen-warning flex items-center justify-center py-6 px-4 border-t border-t-gray-200">
                        <IonIcon icon={warning} size="large" className="flex-none mr-4 text-mycia-red"></IonIcon>

                        <p className="text-sm">{t("dishmodalcontent.p_3")}</p>
                    </div> : null}

                {user ? filtered ? <div className="logincia-warning flex items-center justify-center py-6 px-4">
                    <p className="text-sm text-mycia-medium">{t("dishmodalcontent.p_4")}</p>
                </div> : null : null}
            </div>
        </div>
    );
}
