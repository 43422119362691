function getCoupon(couponId, restaurant) {
    let results;

    restaurant.coupons.forEach(coupon => {
        if (String(couponId) === String(coupon.code)) {
            results = {
                accepted: true,
                code: coupon.code,
                discount: coupon.percentage,
                name: coupon.description,
                restaurantAccepted: restaurant.id,
            }
        }
    });

    if (results) {
        return results;
    }

    return {
        accepted: false,
        discount: "",
    }
}

const couponsClient = {
    getCoupon,
};
export default couponsClient;
