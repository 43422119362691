import React, { useLayoutEffect } from 'react';
import { IonModal } from '@ionic/react';
import SubscriptionModalContent from './SubscriptionModalContent';

function SubscriptionModalTrigger(props) {
    const { showModal, setShowModal } = props;

    // const onOpenHandler = () => setShowModal(true);
    const onCloseHandler = () => setShowModal(false);

    useLayoutEffect(() => {
        setTimeout(() => setShowModal(true), 1000);
    }, [setShowModal]);

    return (
        <div className="subscription-modal-trigger py-1">

            <IonModal cssClass="subscription-modal-trigger" mode="md" isOpen={showModal} onDidDismiss={onCloseHandler}>
                <SubscriptionModalContent closerHandler={onCloseHandler} />
            </IonModal>
        </div>
    );
}

export default SubscriptionModalTrigger;
