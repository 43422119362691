/*const courseEng = [
    { "id": 72144, "course_id": 72144, "original_name": "para empezar", "name": "to stop", "weight": 21 },
    { "id": 71933, "course_id": 71933, "original_name": "para empezar \/ tapas", "name": "to eat \/ tapas", "weight": 20 },
    { "id": 71934, "course_id": 71934, "original_name": "para empezar \/ nachos", "name": "to fill \/ nachos", "weight": 19 },
    { "id": 71937, "course_id": 71937, "original_name": "para empezar \/ quesadillas", "name": "to fill \/ quesadillas", "weight": 18 },
    { "id": 71938, "course_id": 71938, "original_name": "los classico \/ 4 tacos (scegli due ricette)", "name": "classic los \/ 4 tacos (choose two recipes)", "weight": 17 },
    { "id": 71939, "course_id": 71939, "original_name": "los classico \/ burritos", "name": "classics \/ burritos", "weight": 16 },
    { "id": 71940, "course_id": 71940, "original_name": "platos fuertes \/ fajitas", "name": "platos fuertes \/ fajitas", "weight": 15 },
    { "id": 72012, "course_id": 72012, "original_name": "platos fuertes \/ burger & meat", "name": "platos fuertes \/ burger &amp; meat", "weight": 14 },
    { "id": 72013, "course_id": 72013, "original_name": "platos fuertes \/ super protein bowl", "name": "platos fuertes \/ super protein bowl", "weight": 13 },
    { "id": 72014, "course_id": 72014, "original_name": "menu ni\u00f1os (fino a 10 anni di et\u00e0) \/ piatti", "name": "children&#39;s menu (up to 10 years of age) \/ dishes", "weight": 12 },
    { "id": 72097, "course_id": 72097, "original_name": "menu ni\u00f1os (fino a 10 anni di et\u00e0) \/ contorno", "name": "children&#39;s menu (up to 10 years of age) \/ side dish", "weight": 11 },
    { "id": 72098, "course_id": 72098, "original_name": "menu ni\u00f1os (fino a 10 anni di et\u00e0) \/ bevanda", "name": "children&#39;s menu (up to 10 years of age) \/ drink", "weight": 10 },
    { "id": 72015, "course_id": 72015, "original_name": "dulces in fundo", "name": "sweets at the end", "weight": 9 },
    { "id": 72033, "course_id": 72033, "original_name": "bebidas \/ cervezas", "name": "drinks \/ cervezas", "weight": 8 },
    { "id": 72034, "course_id": 72034, "original_name": "bebidas \/ bebidas frias", "name": "drinks \/ drinks frias", "weight": 7 },
    { "id": 72035, "course_id": 72035, "original_name": "bebidas \/ sangria", "name": "drinks \/ sangria", "weight": 6 },
    { "id": 72036, "course_id": 72036, "original_name": "bebidas \/ frozen margarita", "name": "drinks \/ frozen margaritas", "weight": 5 },
    { "id": 72038, "course_id": 72038, "original_name": "bebidas \/ cocktail", "name": "drinks \/ cocktails", "weight": 4 },
    { "id": 72037, "course_id": 72037, "original_name": "bebidas \/ cocktail sin alcohol", "name": "drinks \/ cocktails without alcohol", "weight": 3 },
    { "id": 72032, "course_id": 72032, "original_name": "bebidas \/ caffetteria", "name": "drinks \/ cafeteria", "weight": 2 },
    { "id": 72095, "course_id": 72095, "original_name": "pausa pranzo (lun - ven, dalle 12 alle 15)", "name": "lunch break (Mon - Fri, from 12pm to 3pm)", "weight": 1 }
];*/

/**
 * helper function for dealing with the array case. It adds the category in the array of categories if not present (or finds it if present) and then returns it
 * @param {({name: String}[]|{id: Number, name: String, original_name: String, weight: Number}[])} catArray - an array of categories with different properties
 * @param {{id: Number, name: String, weight: Number}} subCategory - category to be added into the array or to be found
 * @returns the newly created category (or found category)
 */
function manageArrayEntry(catArray, subCategory) {
    let idx = catArray.findIndex((elem) => elem.tree_name === subCategory.tree_name);
    if (idx === -1) {
        catArray.push(subCategory);
        return catArray[catArray.length - 1];
    } else {
        return catArray[idx];
    }
}

/**
 * add the category if not present (or finds it if present) and then returns it
 * @param {(Object|Object[])} category - an array representing a set of categories, or an object representing a specific category
 * @param {Object} subCategory - the new category to be added or found in the array or the object
 * @returns the newly created category (or found category)
 */
function addCategory(category, subCategory) {
    if (Array.isArray(category)) {
        return manageArrayEntry(category, subCategory);
    } else if (typeof category === 'object' && category !== null && category.hasOwnProperty('nested')) {
        return manageArrayEntry(category.nested, subCategory);
    } else if (typeof category === 'object' && category !== null) {
        category.nested = [
            subCategory,
        ];
        return category.nested[category.nested.length - 1];
    }
}

/**
 * recursive function that given an array of categories or a category object is going to build the nested categories defined in the path array
 * @param {(Object|Object[])} category - an array of categories (initially empty) or a specific category object
 * @param {String[]} path - array representing a list of nested categories
 * @param {{id: Number, name: String, original_name: String, weight: Number}} apiEntry - flat category object obtained from the api
 * @param {Number} depth - nesting depth
 * @returns the categories nested exactly as the path is defined
 */
function buildPath(category, path, apiEntry, depth) {
    if (path.length > 0) {
        let subCategory = addCategory(category, { ...apiEntry, name: apiEntry.name.split(" /-/ ")[depth], tree_name: path[0], depth: depth });
        depth += 1;
        buildPath(subCategory, path.slice(1), apiEntry, depth);
    }
}

/**
 * given an array of flat categories, it's going to arrange them into a nested structure of sub categories
 * @param {{id: Number, name: String, original_name: String, weight: Number}[]} menu - flat array of menu categories, as defined in the API endpoint
 * @returns nested structure of menu subcategories
 */
function buildCategoriesTree(menu) {
    let categoriesTree = [];

    menu.sort((category1, category2) => category2.weight - category1.weight);
    menu.forEach(category => {
        buildPath(categoriesTree, category.original_name.split(" /-/ "), category, 0);
    });

    return categoriesTree;
}

// let tree = buildCategoriesTree(courseEng);

// console.dir(tree, { depth: null });

const categoriesBuilder = {
    buildCategoriesTree,
};
export default categoriesBuilder;


