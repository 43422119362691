import React from 'react';
import { useCart } from '../../utils/contexts/cart-context';

function CustomerAdder() {

    const { customers, setCustomers } = useCart();

    function onSubOneHandler() {
        if (customers > 1) {
            let number = customers - 1;
            setCustomers(number);
        }
    }

    function onAddOneHandler() {
        let number = customers + 1;
        setCustomers(number);
    }

    return (
        <div className="adder flex justify-center items-center text-xl font-bold">
            <button type="button" onClick={onSubOneHandler} className="p-4">-</button>
            <span className="quantity mx-2 py-2 px-4 rounded-full border-2 border-mycia-green">{customers}</span>
            <button type="button" onClick={onAddOneHandler} className="p-4">+</button>
        </div>
    );
}

export default CustomerAdder;
