import { IonContent, IonIcon, IonPage } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router';
import Openings from '../components/UI/Openings';
import { useRestaurant } from '../utils/contexts/restaurant-context';
import GoogleMapReact from 'google-map-react';

function InfoPage() {
    const { restaurant } = useRestaurant();
    const history = useHistory();

    return (
        <IonPage>
            <IonContent>
                <div className="p-4 relative font-sans">
                    <div className="pb-4 flex pt-3 items-center">
                        <button type="button" onClick={() => history.goBack()} className="back-button absolute flex items-center justify-center p-3 rounded-full shadow-lg bg-white">
                            <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                        </button>

                        <h2 className="w-full text-center font-bold text-2xl">Informazioni</h2>
                    </div>
                    <div className="border-b border-black py-3">
                        <p className="text-2xl font-semibold">Info del locale</p>
                        <p className="py-3">{restaurant.description}</p>
                        <p className="pb-3">Coperto: <span className="font-bold">{restaurant.cover_charge}€</span></p>
                    </div>

                    <div className="py-6 border-b border-black">
                        <p className="pb-1 text-xl font-semibold">Orari</p>
                        <Openings timetables={restaurant.timetables_formatted} />
                    </div>

                    <div className="py-6">
                        <p className="font-semibold text-xl">Servizi</p>
                        {restaurant.amenities.length > 0 ?
                            restaurant.amenities.map((a, i) =>
                                <div className="flex items-center" key={i}>
                                    <img className="rounded-full border w-12 my-3 mr-3" alt={a.name} src={a.image_path}></img>
                                    <p>{a.name}</p>
                                </div>
                            )
                            : <p>Nessun servizio</p>}
                    </div>)

                    <div>
                        <p className="pb-2 font-semibold text-xl">Mappa</p>

                        <div style={{ width: "100%", height: "350px" }}>
                            <GoogleMapReact
                                defaultCenter={{
                                    lat: restaurant.latitude,
                                    lng: restaurant.longitude
                                }}
                                defaultZoom={12}>
                                <div lat={restaurant.latitude} lng={restaurant.longitude} onClick={() => window.open(`https://maps.google.com/maps/place/${restaurant.latitude},${restaurant.longitude}`)}>
                                    <svg rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns="http://www.w3.org/2000/svg" height="24" width="24" version="1.1" cc="http://creativecommons.org/ns#" dc="http://purl.org/dc/elements/1.1/">
                                        <g transform="translate(0 -1028.4)">
                                            <path d="m12 0c-4.4183 2.3685e-15 -8 3.5817-8 8 0 1.421 0.3816 2.75 1.0312 3.906 0.1079 0.192 0.221 0.381 0.3438 0.563l6.625 11.531 6.625-11.531c0.102-0.151 0.19-0.311 0.281-0.469l0.063-0.094c0.649-1.156 1.031-2.485 1.031-3.906 0-4.4183-3.582-8-8-8zm0 4c2.209 0 4 1.7909 4 4 0 2.209-1.791 4-4 4-2.2091 0-4-1.791-4-4 0-2.2091 1.7909-4 4-4z" transform="translate(0 1028.4)" fill="#e74c3c" />
                                            <path d="m12 3c-2.7614 0-5 2.2386-5 5 0 2.761 2.2386 5 5 5 2.761 0 5-2.239 5-5 0-2.7614-2.239-5-5-5zm0 2c1.657 0 3 1.3431 3 3s-1.343 3-3 3-3-1.3431-3-3 1.343-3 3-3z" transform="translate(0 1028.4)" fill="#c0392b" />
                                        </g>
                                    </svg>
                                </div>
                            </GoogleMapReact>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default InfoPage;
