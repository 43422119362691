
function getDeviceLanguages() {
    return ((navigator.languages) && navigator.languages) || [];
}

function getDeviceLanguage(def = "it_IT") {
    return (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage || def;
}

function isDeviceLanguage(lang) {
    let deviceLanguage = getDeviceLanguage();
    return deviceLanguage === lang;
}

function isCleanDeviceLanguage(lang) {
    let deviceLanguage = getDeviceLanguage();
    let cleanDeviceLanguage = [deviceLanguage[0] + deviceLanguage[1]];
    return cleanDeviceLanguage === lang;
}

function isCleanDeviceLanguageV2(lang) {
    let deviceLanguage = getDeviceLanguage();
    let cleanDeviceLanguage = deviceLanguage[0] + deviceLanguage[1];
    return cleanDeviceLanguage === lang;
}

function getApiLanguage() {
    let exclude = ["zh-CN"];
    let lang = getDeviceLanguage();

    return exclude.includes(lang) ? lang : lang.substr(0, 2);
}

const dl = {
    getDeviceLanguages,
    getDeviceLanguage,
    isDeviceLanguage,
    isCleanDeviceLanguage,
    isCleanDeviceLanguageV2,
    getApiLanguage,
};
export default dl;
